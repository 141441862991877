import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import {
  getCarrierName,
  getCarrierColor,
  formatHealthPlanNameWithDetail as formatPlanName,
} from '@catch-co/health-utils';

import {
  CostRow,
  StyledPillSelect,
  TextButton,
  Button,
  Hud,
  Accordion,
  SourceLogo,
  Rating,
} from '.';
import {
  BENEFIT_ICONS,
  BENEFIT_NAMES,
  _fixStateMetal,
  sortDeduct,
  EHB,
  DISPLAY,
} from 'src/utils/health';
import { $t, formatCostCoverage, formatCostCoverageTop } from 'src/utils';

import { ReactComponent as Doc } from 'src/iconic/file-text-2.svg';
import { ReactComponent as Cost } from 'src/iconic/dollar.svg';
import { ReactComponent as Checklist } from 'src/iconic/shield-tick.svg';
import { ReactComponent as Directory } from 'src/iconic/search.svg';
import { ReactComponent as Star } from 'src/iconic/star.svg';
import { Segment, SegmentEvent } from 'src/lib';
import { Annotations } from './Annotation';
import { useExplorer } from 'src/hooks/useExplorer';

const SButtonHolder = styled.div`
  position: sticky;
  bottom: calc(var(--side-margin) / 2);
  padding-right: calc(var(--side-margin) / 2);
  padding-left: calc(var(--side-margin) / 2);
  display: flex;
  justify-content: flex-end;
  z-index: 9999999999999999;
  .button {
    box-shadow: 0 0 72px 24px var(--c-bg-c);
  }
  @media (max-width: 768px) {
    left: calc(var(--side-margin) / 2);
    .button {
      width: 100%;
    }
  }
`;

/**
 *
 *
 * @todo needs an IA redesign
 *
 * @see HealthSherpa: https://bit.ly/3qP9wJ5
 * @see Stride: https://bit.ly/3qXjojX
 * @see Oscar: https://bit.ly/3xB4mnV
 *
 *
 */
export const HealthPolicy = ({
  id,
  name,
  grossPremium,
  netPremium,
  issuer,
  onNext,
  metal,
  type,
  benefits,
  annotations,
  topLevelItems,
  providerPlan = {},
  isDental,
  onInfo,
  standard,
  qualityRating,
  healthContext,
}) => {
  const fName = formatPlanName(name);
  const title = fName?.name;
  const subtitle = fName?.detail;
  const [currentTab, setCurrentTab] = useState(1);
  const { upsertExplorer } = useExplorer();

  const { deductibles = [], moops = [] } = providerPlan;
  const costCoverage = formatCostCoverage(benefits);
  const topCoverage = formatCostCoverageTop(topLevelItems);
  const sortedDeductibles = useMemo(() => [...deductibles]?.sort(sortDeduct), [deductibles]);

  const labels = {
    individual: $t({ id: 'explorer/plans.individual', defaultMessage: 'Individual' }),
    family: $t({ id: 'explorer/plans.family', defaultMessage: 'Family' }),
    deductible: $t({ id: 'explorer/plans.deductible', defaultMessage: 'Deductible' }),
    medical: $t({ id: 'explorer/plans.medical', defaultMessage: 'Medical' }),
    drug: $t({ id: 'explorer/plans.drug', defaultMessage: 'Drug' }),
    combined: $t({ id: 'explorer/plans.combined', defaultMessage: 'Medical + Drug' }),
    dental: $t({ id: 'explorer/plans.dental', defaultMessage: 'Dental' }),
    moop: $t({ id: 'explorer/plans.moop', defaultMessage: 'out-of-pocket max' }),
    beforeDeductible: $t({
      id: 'explorer/plans.before-deductible',
      defaultMessage: 'Before deductible',
    }),
    afterDeductible: $t({
      id: 'explorer/plans.after-deductible',
      defaultMessage: 'After deductible',
    }),
    commonCosts: $t({ id: 'explorer/plans.common-costs', defaultMessage: 'Common costs' }),
    ehb: $t({ id: 'explorer/plans.ehb', defaultMessage: 'Essential Health Benefits' }),
  };

  const getDeductibleLabel = (d) => {
    const size = d?.familyCost === 'Family' ? 'family' : 'individual';
    const type = /Combined/.test(d?.type)
      ? 'combined'
      : /Medical/.test(d?.type)
        ? 'medical'
        : 'drug';
    return `${labels[size]} ${labels.deductible} (${labels[type]})`;
  };

  return (
    <>
      <div className="split-sidebar-group">
        <div style={{ background: getCarrierColor(issuer), height: 110 }} />
        <div className="split-sidebar-inner">
          <div
            style={{
              border: '2px solid var(--c-bg-gray)',
              borderRadius: 999,
              marginTop: -36 - 68 / 2,
              flexGrow: 0,
              width: 64 + 4,
            }}
          >
            <SourceLogo size={64} source={providerPlan?.issuer?.name} collection="carriers" />
          </div>

          <div
            className="flex-h"
            style={{ justifyContent: 'space-between', alignItems: 'center', height: 48 }}
          >
            <div
              className="m"
              style={{
                whiteSpace: 'nowrap',
                WebkitMaskImage: 'linear-gradient(to right, black 90%, transparent )',
                flexGrow: 1,
                width: '100%',
              }}
            >
              {getCarrierName(issuer)}
            </div>
            <div
              className="flex no-mobile"
              style={{ display: 'flex', flex: 0, alignItems: 'baseline', marginRight: -12 }}
            >
              {!!providerPlan?.networkURL && (
                <TextButton
                  icon={Directory}
                  text={$t({ id: 'explorer/plans.providers', defaultMessage: 'Providers' })}
                  onPress={() => window.open(providerPlan?.networkURL)}
                />
              )}
              {!!providerPlan?.benefitsURL && (
                <TextButton
                  icon={Doc}
                  text={$t({ id: 'explorer/plans.brochure', defaultMessage: 'PDF' })}
                  onPress={() => window.open(providerPlan?.benefitsURL)}
                />
              )}
            </div>
          </div>

          <div>
            <div>
              <h3
                style={{
                  marginTop: 0,
                  marginBottom: 4,
                  display: 'block',
                  fontWeight: 500,
                }}
              >
                {title || <Skeleton />}
              </h3>

              <b2
                style={{
                  fontWeight: 400,
                  marginBottom: 8,
                  fontVariationSettings: '"opsz" 24',
                  lineHeight: 1.3,
                  color: 'var(--c-fg-1)',
                }}
              >
                <span>{subtitle || (!title ? <Skeleton /> : '  ')}</span>
              </b2>
              <fp
                style={{
                  color: 'var(--c-fg-3)',
                }}
              >
                <span className="tnum  ">{providerPlan?.id}</span>
              </fp>
            </div>

            <Hud
              loading={!title}
              metal={metal && _fixStateMetal(metal)}
              network={type}
              standard={standard}
              dental={isDental ? false : providerPlan?.hasDental}
              vision={providerPlan?.hasVision}
              hsa={providerPlan?.hsaEligible}
              referral={providerPlan?.specialistReferralRequired}
              id={providerPlan?.id}
              onInfo={onInfo}
            />

            <Annotations annotations={annotations} />
          </div>

          {title && (
            <div>
              <div>
                <CostRow
                  large
                  onInfo={() => onInfo('aptc')}
                  value={netPremium - (grossPremium > netPremium ? DISPLAY : 0)}
                  prevValue={grossPremium}
                  label={$t({ id: 'explorer/plans.premium', defaultMessage: 'Monthly Premium' })}
                  unit={$t({ id: 'health-plan.month-abbr', defaultMessage: 'mo' })}
                />

                {sortedDeductibles?.map((d, idx) => (
                  <CostRow
                    key={d?.type + d?.familyCost + 'D'}
                    large={idx === 0}
                    value={d?.amount}
                    onInfo={idx === 0 ? () => onInfo('moop') : null}
                    label={getDeductibleLabel(d)}
                    unit={$t({ id: 'health-plan.year-abbr', defaultMessage: 'yr' })}
                  />
                ))}

                {[...moops]
                  ?.sort(sortDeduct)
                  ?.map((d, idx) => (
                    <CostRow
                      unit={$t({ id: 'health-plan.year-abbr', defaultMessage: 'yr' })}
                      onInfo={idx === 0 ? () => onInfo('moop') : null}
                      key={d?.type + d?.familyCost + 'M'}
                      value={d?.amount}
                      label={`${d?.familyCost?.replace('Family Per Person', labels.individual)} ${
                        labels.moop
                      }`}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>
        {!!title && (
          <div className="split-sidebar-inner padded" style={{ background: 'var(--bgaa)' }}>
            <div style={{ margin: '0 0 24px' }}>
              <StyledPillSelect value={currentTab} onChange={(e) => setCurrentTab(e.target.value)}>
                <option value={0}>{labels.beforeDeductible}</option>
                <option value={1}>{labels.afterDeductible}</option>
              </StyledPillSelect>
            </div>

            <div>
              {/* A11y: Accordion Items must be wrapped by the same Accordion Root for the arrow key navigation to work */}
              <Accordion
                showCaret={true}
                isCollapsibleList={true}
                items={[
                  {
                    title: $t({
                      id: 'health-plan.star-ratings',
                      defaultMessage: 'Star ratings',
                    }),
                    icon: Star,
                    hidden: !qualityRating.available,
                    content: (
                      <>
                        <CostRow
                          label={$t({
                            id: 'health-plan.overall-rating',
                            defaultMessage: 'Overall rating',
                          })}
                          accessory={<Rating rating={qualityRating.globalRating} />}
                        />
                        <CostRow
                          label={$t({
                            id: 'health-plan.member-experience',
                            defaultMessage: 'Member experience',
                          })}
                          accessory={<Rating rating={qualityRating.enrolleeExperienceRating} />}
                        />
                        <CostRow
                          label={$t({
                            id: 'health-plan.medical-care',
                            defaultMessage: 'Medical care',
                          })}
                          accessory={
                            <Rating rating={qualityRating.clinicalQualityManagementRating} />
                          }
                        />
                        <CostRow
                          label={$t({
                            id: 'health-plan.plan-administration',
                            defaultMessage: 'Plan administration',
                          })}
                          accessory={<Rating rating={qualityRating.planEfficiencyRating} />}
                        />
                      </>
                    ),
                  },
                  {
                    title: labels.commonCosts,
                    icon: Cost,
                    hidden: isDental,
                    content: (
                      <>
                        {Object.values(topCoverage || {}).map((category) =>
                          category?.[currentTab]?.map((item) => (
                            <CostRow key={item.label} label={item.label} rawValue={item.value} />
                          )),
                        )}
                      </>
                    ),
                  },
                  {
                    title: labels.ehb,
                    icon: Checklist,
                    hidden: isDental,
                    subtitle: (
                      <>
                        {$t({
                          id: 'explorer/plans.ehb-subtitle',
                          defaultMessage:
                            'All ACA plans are required to cover the 10 Essential Health Benefits.',
                        })}{' '}
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            onInfo('ehb');
                          }}
                          className="underline"
                          target="_blank"
                        >
                          {$t({
                            id: 'explorer/plans.learn-more',
                            defaultMessage: 'Learn more',
                          })}
                        </span>
                      </>
                    ),
                    content: (
                      <>{EHB?.map((item, idx) => <CostRow key={idx} label={item}></CostRow>)}</>
                    ),
                  },
                  ...Object.entries(costCoverage || {}).map(([key, value]) => ({
                    title: isDental && key === 'visionDental' ? 'Dental' : BENEFIT_NAMES[key],
                    icon: BENEFIT_ICONS[key],
                    hidden: isDental ? key !== 'visionDental' : false,
                    content: (
                      <>
                        {value?.[currentTab]?.map((b) => (
                          <CostRow key={b?.label} label={b?.label} rawValue={b?.value} />
                        ))}
                      </>
                    ),
                  })),
                ]}
              />
            </div>
          </div>
        )}
        {!isDental && (
          <SButtonHolder>
            <Button
              background="textColor"
              primary
              onPress={() => {
                const payload = {
                  sessionId: id,
                  planName: providerPlan?.name,
                  healthContext,
                };

                Segment.track(SegmentEvent.EXPLORER_PLAN_CHOSEN, payload);
                Segment.track(SegmentEvent.PX_PLAN_CHOSEN, payload);

                Segment.identify({
                  health_plan_explorer: providerPlan?.name,
                });

                // chain these so the final upsert always runs before the onNext redirect to another site
                upsertExplorer({ providerPlanID: providerPlan?.id }).then(() => onNext());
              }}
            >
              {$t({ id: 'explorer/plans.continue', defaultMessage: 'Continue with this plan' })}
            </Button>
          </SButtonHolder>
        )}
      </div>
    </>
  );
};

export default HealthPolicy;
