import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { useQueryParam, StringParam } from 'use-query-params';
import CountUp from 'react-countup';
import { getCarrierLogo } from '@catch-co/health-utils';

import { HealthPlanAnonymous, MetalTier, NetworkType } from 'src/types/health';
import { SinglePlanView } from '.';
import { DoctorsPage, DrugsPage, Filters, UsagePage } from './partials';
import { PLANS, ProductType, Carrier } from 'src/data';
import { usePartner } from 'src/hooks';
import { SessionContext } from 'src/context/SessionContext';
import {
  $t,
  capitalize,
  formatCurrency,
  getDeductible,
  properCase,
  toProperCase,
  calcEligibility,
} from 'src/utils';

import {
  HealthPolicyBlock,
  Modal,
  SplitLayout,
  StyledPillSelect,
  TextButton,
  FilterPill,
  ComparisonChartOriginal,
  Callout,
  SnippetModal,
} from '../components';

import { ReactComponent as FilterIcon } from 'src/iconic/settings-sliders.svg';
import { _fixStateMetal, DISPLAY } from 'src/utils/health';
import { Segment, SegmentEvent } from 'src/lib';
import { useExplorer } from 'src/hooks/useExplorer';

/**
 *
 *
 * Plans Page
 *
 */
const PlansPage = ({ isHandoff }) => {
  const [sortBy, setSortBy] = useState('PREMIUM');
  const [planId] = useQueryParam('planId', StringParam);
  const [planIds, setPlanIds] = useState([]);
  const [comparing, setComparing] = useState(false);
  const [snippet, setSnippet] = useState();
  const [open, setOpen] = useState('');
  const [division, setDivision] = useState<ProductType>(ProductType.Health);
  const [filtersOpen, setFiltersOpen] = useState(true);

  // Filters
  const [premium, setPremium] = useState<number>(5000);
  const [deductible, setDeductible] = useState<number>(18200);
  const [issuers, setIssuers] = useState<Array<string>>([]);
  const [networks, setNetworks] = useState<Array<NetworkType>>([]);
  const [metals, setMetals] = useState<Array<MetalTier>>([]);

  // @todo use PLACEHOLDERS from Filters.tsx as type
  const [filters, setFilters] = useState({
    Standard: false,
    HSA: false,
  });

  const [options, setOptions] = useState({
    'Standard Naming': false,
  });

  const clear = (force) => {
    setPremium(force ? 3000 : maxPremium);
    setDeductible(force ? 17400 : maxDeductible);
    setNetworks([]);
    setMetals([]);
    setIssuers([]);
    setFilters({
      Standard: false,
      HSA: false,
    });
  };

  const { loading: explorerLoading, data, upsertExplorer } = useExplorer();
  const income = data?.income;
  const zip = data?.zipcode;
  const state = data?.state;
  const pathway = data?.pathwayType;
  const docs = data?.providersWanted;
  const drugs = data?.drugsWanted;
  const usage = data?.plannedUsage;
  const hasDependents = data?.dependents?.length && data.dependents.length > 1;
  const { partner, adminPxLink, isAetnaSessionActive } = useContext(SessionContext);

  const { color } = usePartner({ slug: partner });
  const isEde = pathway === 'EDE';

  useEffect(() => {
    if (!explorerLoading) {
      if (!data.zipcode || !data.coverageYear || !data.dependents) {
        navigate('/explorer/zip');
      }
    }
  }, [explorerLoading]);

  // health plans
  const {
    data: dataEde,
    loading: loadingEde,
    error,
  } = useQuery(PLANS, {
    skip: !data.id,
    variables: {
      id: data.id || '',
      input: {
        productType: ProductType.Health,
        carriers: isAetnaSessionActive ? [Carrier.Aetna, Carrier.InnovationHealth] : undefined,
      },
    },
  });

  // dental plans
  const { data: dataDental, loading: loadingDental } = useQuery(PLANS, {
    skip: !data.id,
    variables: {
      id: data.id || '',
      input: {
        productType: ProductType.Dental,
      },
    },
  });

  const _plans = useMemo(() => {
    const planList =
      division === ProductType.Health
        ? dataEde?.getPublicHealthExplorerData?.plans?.plans || []
        : dataDental?.getPublicHealthExplorerData?.plans?.plans || [];

    return planList?.map((p) => ({
      ...p,
      healthPlan: {
        ...p?.healthPlan,
        metalLevel: _fixStateMetal(p?.healthPlan?.metalLevel),
        type: p?.healthPlan?.type?.toUpperCase(),
      },
    }));
  }, [division, dataEde, dataDental]);

  const loading = useMemo(() => {
    // note: since we're pulling data from the data ede query for both views,
    // if that query is loading we show the loading state, even if dental plans have resolved
    // this could be improved in the future by showing a separate loading state for eligibility, for example
    return loadingEde || loadingDental || (division === ProductType.Dental && loadingDental);
  }, [division, loadingEde, loadingDental]);

  const {
    aptc,
    csr,
    isMedicaidChip,
    medicaidChipHousehold,
    formattedMedicaidChipMembers,
    formattedMedicaidChipPrograms,
  } = useMemo(() => {
    return calcEligibility({
      marketplaceEligibility: dataEde?.getPublicHealthExplorerData?.marketplaceEligibility,
      dependents: dataEde?.getPublicHealthExplorerData?.dependents,
    });
  }, [dataEde]);

  const hasAptc = !!aptc && aptc > 0;

  const { _premiums, _duds } = !!_plans
    ? _plans.reduce<{ _premiums: number[]; _duds: number[] }>(
        (acc, p) => {
          acc._premiums.push(p?.healthPlan?.premiumWithCredit);

          const deductible = p?.healthPlan?.deductibles?.[0]?.amount;
          //use only plans with valid deductible data to calculate the max deductible
          if (deductible !== null && deductible !== undefined) {
            acc._duds.push(deductible);
          }
          return acc;
        },
        { _premiums: [], _duds: [] },
      )
    : { _premiums: [], _duds: [] };

  const minPremium = useMemo(() => Math.min(..._premiums) || 0, [_premiums]);
  const maxPremium = useMemo(() => Math.max(..._premiums), [_premiums]);
  const maxDeductible = useMemo(() => Math.max(..._duds), [_duds]);

  // updates premium + deductible if the range changes
  useEffect(() => setPremium(maxPremium), [maxPremium]);
  useEffect(() => setDeductible(maxDeductible), [maxDeductible]);

  const isInFilter = useCallback(
    (p) =>
      (metals?.includes(p?.healthPlan?.metalLevel?.replace('Expanded Bronze', 'Bronze')) ||
        metals.length === 0 ||
        !metals) &&
      (networks?.includes(p?.healthPlan?.type) || networks.length === 0 || !networks) &&
      p?.healthPlan?.premiumWithCredit <= premium &&
      (p?.healthPlan?.deductibles?.length === 0 ||
        p?.healthPlan?.deductibles?.[0]?.amount <= deductible) &&
      (issuers.includes(p?.healthPlan?.issuer?.name) || issuers.length === 0 || !issuers) &&
      (filters.HSA ? p?.healthPlan?.hsaEligible : true) &&
      (filters.Standard ? p?.healthPlan?.isStandard : true),
    [premium, deductible, networks, issuers, metals, filters],
  );

  const inFilter = useMemo(
    () => _plans.filter(isInFilter)?.map((p) => p.healthPlan.id),
    [_plans, premium, deductible, networks, issuers, metals, filters],
  );

  const allPlans = useMemo(
    () =>
      [...(_plans || [{}, {}, {}])]
        .sort((a, b) => {
          return sortBy === 'RECOMMENDED'
            ? 0
            : sortBy === 'PREMIUM'
              ? a?.healthPlan?.premiumWithCredit - b?.healthPlan?.premiumWithCredit
              : sortBy === 'DEDUCTIBLE'
                ? getDeductible(a?.healthPlan) - getDeductible(b?.healthPlan)
                : 0;
        })
        .map((p) => ({
          ...p,
          isLowestPremium: _premiums ? p?.healthPlan?.premiumWithCredit === minPremium : false,
          isInFilter: inFilter.includes(p?.healthPlan?.id),
        })),
    [inFilter, _plans, sortBy, hasDependents],
  );

  const newPlans: Array<HealthPlanAnonymous> = useMemo(
    () =>
      allPlans?.map((p) => ({
        premium: Math.max(0, p?.healthPlan?.premiumWithCredit - (aptc ? DISPLAY : 0)),
        deductible: p?.healthPlan?.deductibles?.[0]?.amount,
        metal: properCase(p?.healthPlan?.metalLevel?.replace('expanded_bronze', 'bronze')),
        network: p?.healthPlan?.type,
        isInFilter: p?.isInFilter,
        issuer: p?.healthPlan?.issuer?.name,
        brochureUrl: p?.healthPlan?.brochureURL,
      })),
    [allPlans],
  );

  // for the filter pill
  var numFilters = 0;
  if (premium < Math.floor(maxPremium)) numFilters++;
  if (deductible < Math.floor(maxDeductible)) numFilters++;
  if (metals?.length > 0) numFilters++;
  if (networks?.length > 0) numFilters++;
  if (issuers?.length > 0) numFilters++;
  numFilters += Object.values(filters).filter((f) => f).length;

  const numPlans = newPlans?.length;
  const numFilteredPlans = newPlans?.filter((p) => p?.isInFilter)?.length;
  const plansToShow = allPlans?.filter((p) => p.isInFilter) || [{}, {}, {}, {}];

  const aptcCallout = useMemo(() => {
    return plansToShow?.length > 0
      ? {
          id: 'health-eligibility.aptc-description',
          defaultMessage: "We'll make sure you get all available {credits}",
        }
      : {
          id: 'health-eligibility.no-plans-aptc-description',
          defaultMessage:
            'Although no plans match your filters, you are still eligible for {credits}. Try adjusting your filters to find available plans',
        };
  }, [plansToShow?.length]);

  return (
    <>
      <SplitLayout
        progress={0}
        largeTitle
        wideTitle
        maxMobile
        title={$t({ id: 'explorer/plans.title', defaultMessage: 'Plans' })}
        filtersOpen={!!filtersOpen}
        sidebarOpen={!!planId}
        sidebarContent={
          planId && (
            <SinglePlanView isHandoff={isHandoff} id={planId} onInfo={(slug) => setSnippet(slug)} />
          )
        }
        accessory={
          <DynamicIsland open={planIds?.length > 0} count={planIds?.length} comparing={comparing}>
            <div>
              <div
                onClick={() => {
                  Segment.track(SegmentEvent.PX_OPEN_COMPARISON, { planIds });
                  setComparing(true);
                }}
              >
                <div
                  className="imgs"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexShrink: 0,
                    height: '100%',
                    width: 26 * planIds?.length,
                  }}
                >
                  {planIds?.map((p) => (
                    <div
                      key={p}
                      style={{
                        backgroundImage: `url(https://s.catch.co/img/carriers/${getCarrierLogo(
                          p.issuer,
                        )}.png)`,
                        ...styles.planids,
                      }}
                    />
                  ))}
                </div>
                <div style={{ marginLeft: 18 }}>
                  {$t({
                    id: 'explorer/plans.compare',
                    defaultMessage: 'Compare {num} plans',
                    data: { num: planIds?.length },
                  })}
                </div>
              </div>
              <div onClick={() => setPlanIds([])}>
                {$t({ id: 'health-plans.Clear', defaultMessage: 'Clear' })}
              </div>
            </div>
          </DynamicIsland>
        }
        initial={
          <>
            <div className="nav-standin" />
            <div style={{ marginTop: -24, marginBottom: 24, opacity: numFilters > 0 ? 1 : 0.25 }}>
              <TextButton
                text={$t({ id: 'health-plans.Clear', defaultMessage: 'Clear' })}
                onPress={clear}
              />
            </div>
            <Filters
              plans={newPlans}
              premium={premium}
              deductible={deductible}
              metals={metals}
              issuers={issuers}
              networks={networks}
              setPremium={setPremium}
              setDeductible={setDeductible}
              setNetworks={setNetworks}
              setMetals={setMetals}
              setIssuers={setIssuers}
              maxPremium={maxPremium}
              minPremium={minPremium}
              maxDeductible={maxDeductible}
              minDeductible={0}
              color={color}
              filters={filters}
              setFilters={setFilters}
              isDental={division === 'DENTAL'}
              onInfo={(slug) => setSnippet(slug)}
              options={options}
              setOptions={setOptions}
            />
          </>
        }
        onClose={() => {
          navigate(`/explorer/plans?sid=${data.id}`);
        }}
        subtitle={
          <>
            <b2
              className={loading && 'pulse'}
              style={{
                marginBottom: 2,
                color: loading ? color || 'var(--c-fg-0)' : 'var(--c-fg-1)',
              }}
            >
              {error
                ? $t({ id: 'explorer/plans.offline', defaultMessage: 'Offline' })
                : loading
                  ? $t({ id: 'loading', defaultMessage: 'Loading...' })
                  : numPlans === 0
                    ? null
                    : numFilteredPlans === numPlans
                      ? $t({
                          id: 'explorer/plans.all-plans',
                          defaultMessage: 'All {num} plans',
                          data: { num: numPlans },
                        })
                      : $t({
                          id: 'explorer/plans.filtered-plans',
                          defaultMessage: 'All {filtered} of {num} plans',
                          data: { filtered: numFilteredPlans, num: numPlans },
                        })}
              <br />
            </b2>
            <div>
              {$t({
                id: 'explorer/plans.household',
                defaultMessage: 'for {location} with household income of {income}',
                data: {
                  location: (
                    <Link className="link underline" to="../zip">
                      {zip}, {state}
                    </Link>
                  ),
                  income: (
                    <Link className="link underline" to="../income">
                      {income ? formatCurrency({ value: income }) : 'none'}
                    </Link>
                  ),
                },
              })}
            </div>
          </>
        }
      >
        <StyledFilterRow className="flex-h">
          <div className="desktop-only">
            <FilterPill
              onPress={() => setFiltersOpen(!filtersOpen)}
              name="Filter"
              icon={FilterIcon}
              active={numFilters}
            />
          </div>
          <div className="mobile-only">
            <FilterPill active={numFilters} onPress={setOpen} name="Filter" icon={FilterIcon} />
          </div>
          {!!isEde && (
            <div>
              <div className="aid">{$t({ id: 'health-filters.type', defaultMessage: 'Type' })}</div>
              <StyledPillSelect
                name="division"
                value={division}
                onChange={(e) => {
                  navigate(`/explorer/plans?sid=${data.id}`);
                  clear(true);
                  setPlanIds([]);
                  setDivision(e.target.value);
                }}
              >
                <option value={ProductType.Health}>
                  {$t({ id: 'health-filters.health', defaultMessage: 'Health' })}
                </option>
                <option value={ProductType.Dental}>
                  {$t({ id: 'health-filters.dental', defaultMessage: 'Dental' })}
                </option>
              </StyledPillSelect>
            </div>
          )}
          <div>
            <div className="aid">
              {$t({ id: 'health-filters.sort', defaultMessage: 'Sort by' })}
            </div>
            <StyledPillSelect
              name="sort"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
            >
              <option value="RECOMMENDED">
                {$t({ id: 'health-filters.recommended', defaultMessage: 'Recommended' })}
              </option>
              <option value="PREMIUM">
                {$t({ id: 'health-filters.lowest-premium', defaultMessage: 'Lowest Premium' })}
              </option>
              <option value="DEDUCTIBLE">
                {$t({
                  id: 'health-filters.lowest-deductible',
                  defaultMessage: 'Lowest Deductible',
                })}
              </option>
            </StyledPillSelect>
          </div>
          {!!isEde && division !== ProductType.Dental && (
            <FilterPill
              active={drugs?.length}
              onPress={setOpen}
              name="Prescriptions"
              text={$t({ id: 'health-filters.prescriptions', defaultMessage: 'Prescriptions' })}
            />
          )}
          {!!isEde && division !== ProductType.Dental && (
            <FilterPill
              active={docs?.length}
              onPress={setOpen}
              name="Doctors"
              text={$t({ id: 'health-filters.doctors', defaultMessage: 'Doctors' })}
            />
          )}
          {!!isEde && division !== ProductType.Dental && (
            <div>
              <div className="aid">
                {$t({ id: 'health-filters.usage', defaultMessage: 'Usage' })}{' '}
              </div>
              <FilterPill
                active={usage ? ' ' : false}
                onPress={() => setOpen('Usage')}
                text={
                  usage
                    ? toProperCase(usage?.replace('REC_', ''))
                    : `+ ${$t({ id: 'health-filters.usage', defaultMessage: 'Usage' })}`
                }
              />
            </div>
          )}
        </StyledFilterRow>

        <div>
          <div>
            <div style={{ marginTop: 24 }}>
              {/* @TODO remove after window shopping/full plan data */}
              {!loadingEde && allPlans.length === 0 && (
                <Callout
                  smallText
                  imageSrc="/iconic/shield-warning.svg"
                  title="More plans to come, check back soon"
                  bg="var(--c-orange-1)"
                  text="Not all carriers have released plans in your state"
                />
              )}
              {isMedicaidChip && !loading && (
                <Callout
                  smallText
                  imageSrc="/iconic/dollar-2.svg"
                  bg={color ? color + '33' : 'var(--c-success-light)'}
                  title={$t({
                    id: 'health-eligibility.medicaid-chip-title',
                    defaultMessage: '{people} may qualify for {programs}',
                    data: {
                      people: capitalize(formattedMedicaidChipMembers),
                      programs: formattedMedicaidChipPrograms,
                    },
                  })}
                  text={
                    medicaidChipHousehold === 'ALL'
                      ? $t({
                          id: 'health-eligibility.medicaid-chip-all',
                          defaultMessage: `Plans will be listed at full price. You can take a look, but your
                      household may qualify for lower priced plans through {programs}.`,
                          data: { programs: formattedMedicaidChipPrograms },
                        })
                      : $t({
                          id: 'health-eligibility.medicaid-chip-some',
                          defaultMessage: `We've removed {people} from plan quotes. If you apply for coverage,
                      we'll submit their info to your state to determine final eligibility.`,
                          data: { people: formattedMedicaidChipMembers },
                        })
                  }
                />
              )}
              {hasAptc && !loading && (
                <Callout
                  smallText
                  imageSrc="/iconic/dollar-2.svg"
                  bg={color ? color + '33' : 'var(--c-success-light)'}
                  title={$t({
                    id: 'health-eligibility.aptc-title',
                    defaultMessage: 'Save up to {amount}',
                    data: {
                      amount: (
                        <>
                          <span className="b">
                            <CountUp end={aptc + DISPLAY} prefix="$" duration={2} useEasing />
                          </span>
                          <span className="r o75">
                            /{$t({ id: 'health-plan.month-abbr', defaultMessage: 'mo' })}
                          </span>
                        </>
                      ),
                    },
                  })}
                  text={$t({
                    id: aptcCallout.id,
                    defaultMessage: aptcCallout.defaultMessage,
                    data: {
                      credits: (
                        <a
                          href="#"
                          onClick={() => setSnippet('aptc')}
                          className="link"
                          style={{ color: color }}
                        >
                          {$t({
                            id: 'health-eligibility.tax-credits',
                            defaultMessage: 'tax credits',
                          })}
                        </a>
                      ),
                    },
                  })}
                />
              )}

              {pathway === 'STATE_EXCHANGE' && !loading && (
                <Callout
                  smallText
                  imageSrc="/iconic/plus.svg"
                  bg={color ? color + '1A' : 'var(--c-success-lighter)'}
                  title={$t({
                    id: 'health-eligibility.state-subsidy-title',
                    defaultMessage: 'Your household may qualify for additional savings',
                  })}
                  text={$t({
                    id: 'health-eligibility.state-subsidy-description',
                    defaultMessage:
                      'States may offer additional discounts, subsidies, or alternative plans and your household may qualify for health insurance at a lower cost.',
                  })}
                />
              )}

              {csr && !loading && (
                <Callout
                  smallText
                  imageSrc="/iconic/plus.svg"
                  title={$t({
                    id: 'health-eligibility.csr-title',
                    defaultMessage: 'Save more with Silver',
                  })}
                  bg={color ? color + '1A' : 'var(--c-success-lighter)'}
                  text={$t({
                    id: 'health-eligibility.csr-description',
                    defaultMessage: 'You likely qualify for {csr}',
                    data: {
                      csr: (
                        <a
                          href="#"
                          onClick={() => setSnippet('csr')}
                          className="link"
                          style={{ color: color }}
                        >
                          {$t({
                            id: 'health-eligibility.csr',
                            defaultMessage: 'cost-sharing reductions',
                          })}
                        </a>
                      ),
                    },
                  })}
                />
              )}
              {!loading && plansToShow.length === 0 ? (
                <Callout
                  smallText
                  imageSrc="/iconic/shield-warning.svg"
                  title="No plans match your filters"
                  bg="var(--c-orange-1)"
                  text="Try adjusting your filters to see more available plans"
                />
              ) : (
                <div style={{ minHeight: '50vh', marginTop: 24 }}>
                  {(plansToShow?.length > 0 ? plansToShow : [{}, {}, {}, {}])?.map((plan, idx) => (
                    <HealthPolicyBlock
                      selected={plan?.healthPlan?.id && plan?.healthPlan?.id === planId}
                      loading={loading || !plan?.healthPlan}
                      plan={plan}
                      isDental={plan?.healthPlan?.hasDental}
                      brochureURL={plan?.healthPlan?.brochureURL}
                      key={plan?.healthPlan?.id || idx}
                      name={plan?.healthPlan?.name}
                      metal={plan?.healthPlan?.metalLevel}
                      type={plan?.healthPlan?.type}
                      standard={plan?.healthPlan?.isStandard}
                      grossPremium={plan?.healthPlan?.premium}
                      netPremium={plan?.healthPlan?.premiumWithCredit}
                      issuer={plan?.healthPlan?.issuer?.name}
                      deductible={plan?.healthPlan?.deductibles?.[0]?.amount}
                      rawDeductibles={plan?.healthPlan?.deductibles}
                      hsa={plan?.healthPlan?.hsaEligible}
                      qualityRating={plan?.healthPlan?.qualityRating}
                      standardNames={options['Standard Naming']}
                      onInfo={(slug) => setSnippet(slug)}
                      onPress={
                        loading || !plan?.healthPlan
                          ? null
                          : () => {
                              Segment.track(SegmentEvent.PX_PLAN_VIEWED, {
                                pxId: data.id || '',
                                adminPxLink,
                                planId: plan?.healthPlan?.id,
                                carrier: plan?.healthPlan?.issuer?.name,
                                issuer: plan?.healthPlan?.issuer?.name,
                                carrier_group: getCarrierLogo(plan?.healthPlan?.issuer?.name),
                              });
                              upsertExplorer({ providerPlanID: plan?.healthPlan?.id });
                              navigate(
                                `/explorer/plans?sid=${data.id}&planId=${plan?.healthPlan?.id}`,
                              );
                            }
                      }
                      onCompare={() => {
                        if (planIds.map((p) => p.id)?.includes(plan?.healthPlan?.id))
                          setPlanIds(planIds?.filter((p) => p?.id !== plan?.healthPlan?.id));
                        else
                          setPlanIds([
                            { id: plan?.healthPlan?.id, issuer: plan?.healthPlan?.issuer?.name },
                            ...(planIds?.slice(0, 3) || []),
                          ]);
                        return false;
                      }}
                      inCompare={planIds.map((p) => p.id)?.includes(plan?.healthPlan?.id)}
                    />
                  ))}
                </div>
              )}

              <div className="disclosures">
                <b3 style={styles.footerText}>
                  {$t({
                    id: 'health-disclaimer',
                    defaultMessage: `Attention: This website is operated by Catch Insurance and 
                      is not the Health Insurance Marketplace® website. In offering this website, 
                      Catch Insurance is required to comply with all applicable federal law, including 
                      the standards established under 45 CFR §§155.220(c) and (d) and standards 
                      established under 45 CFR §155.260 to protect the privacy and security of personally 
                      identifiable information. This website may not support enrollment in all Qualified 
                      Health Plans (QHPs) being offered in your state through the Health Insurance 
                      Marketplace® website. For enrollment support in all available QHP options in 
                      your state, go to the Health Insurance Marketplace® website at {link}.`,
                    data: {
                      link: <a href="https://healthcare.gov">Healthcare.gov</a>,
                    },
                  })}
                  <br />
                  <br />
                  {$t({
                    id: 'plans-disclaimer',
                    defaultMessage: `Plan recommendations are determined by considering monthly 
                      premiums, deductibles, out-of-pocket costs, anticipated usage, selected 
                      providers, selected prescriptions, and active filters. Recommendations and 
                      sorting do <m>not</m> take  into account any compensation received for the 
                      sale, promotion, or preferential display of insurance products.`,
                  })}
                </b3>
              </div>
            </div>
          </div>
        </div>
      </SplitLayout>

      <UsagePage open={open === 'Usage'} onClose={() => setOpen(false)} />
      <DoctorsPage open={open === 'Doctors'} onClose={() => setOpen(false)} />
      <DrugsPage open={open === 'Prescriptions'} onClose={() => setOpen(false)} />

      <Modal
        open={open === 'Filter' && window.matchMedia('(max-width: 1080px)')?.matches}
        onClose={() => setOpen(false)}
        title={open}
        topItems={
          <TextButton
            text={$t({ id: 'health-plans.Clear', defaultMessage: 'Clear' })}
            onPress={clear}
          />
        }
      >
        {open === 'Filter' && window.matchMedia('(max-width: 1080px)')?.matches && (
          <Filters
            plans={newPlans}
            premium={premium}
            deductible={deductible}
            metals={metals}
            issuers={issuers}
            networks={networks}
            setPremium={setPremium}
            setDeductible={setDeductible}
            setNetworks={setNetworks}
            setMetals={setMetals}
            setIssuers={setIssuers}
            maxPremium={maxPremium}
            minPremium={minPremium}
            maxDeductible={maxDeductible}
            minDeductible={0}
            color={color}
            isDental={division === ProductType.Dental}
            onInfo={(slug) => setSnippet(slug)}
            options={options}
            setOptions={setOptions}
            wide
          />
        )}
      </Modal>

      <ComparisonChartOriginal
        planIds={planIds}
        comparing={comparing}
        setComparing={setComparing}
        id={data.id}
        onInfo={(slug) => setSnippet(slug)}
      />
      <SnippetModal open={!!snippet} slug={snippet} onClose={() => setSnippet()} />
    </>
  );
};

const styles = {
  footerText: {
    color: 'var(--c-fg-1)',
    fontWeight: 400,
    opacity: 0.5,
    marginTop: '5vh',
    marginBottom: '5vh',
    paddingRight: 24,
    fontSize: 13,
    maxWidth: 680,
  },
  planids: {
    flexShrink: 0,
    marginRight: -10,
    height: '100%',
    aspectRatio: '1/1',
    borderRadius: 24,
    backgroundSize: 'cover',
    backgroundColor: 'var(--c-border-light)',
    boxShadow: '0 0 5px 0px var(--c-fg-0)',
  },
};

const StyledFilterRow = styled.div`
  justify-content: flex-start !important;
  position: sticky;
  top: calc(-1 * var(--side-margin) - var(--s-nav-height));
  margin: 24px calc(-1 * var(--side-margin)) 0;
  overflow: scroll;
  align-items: flex-end !important;
  padding: 0 calc(1 * var(--side-margin)) 0;
  z-index: 99999999999999999999999999;
  background: linear-gradient(to bottom, var(--c-bg-0) 50%, var(--c-bg-0t));

  > div {
    flex-shrink: 0;
  }
  .aid {
    font-size: calc(var(--fs-fp) - 0.5px);
    color: var(--c-fg-1);
    font-weight: 450;
    margin-bottom: 1px;
    margin-left: 12px;
  }
`;

const DynamicIsland = styled.div`
  position: fixed;
  bottom: 24px;
  width: ${(p) => (p.open ? 220 + p.count * 26 : 0)}px;
  left: 50%;
  margin-left: ${(p) => (p.open ? -110 - p.count * 26 + 13 : 0)}px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999999999999999999;
  transform: scale(${(p) => (p.open && !p.comparing ? 1 : 0.8)});
  opacity: ${(p) => (p.open ? 1 : 0)};
  transition: 0.25s cubic-bezier(0.21, 0.49, 0.14, 1.15);
  will-change: transform, opacity, width, margin-left;
  box-shadow: 0 0 72px 24px var(--c-bg-0);
  background: var(--c-fg-0);
  border-radius: 99px;
  height: 48px;
  .imgs {
    transition: 0.25s cubic-bezier(0.21, 0.49, 0.14, 1.15);
  }
  > div {
    opacity: ${(p) => (p.open ? 1 : 0)};
    width: ${(p) => (p.open ? 220 + p.count * 26 : 220)}px;
    transition: 0.25s cubic-bezier(0.21, 0.49, 0.14, 1.15);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    > div {
      color: var(--c-bg-0);
      padding: 0 16px;
      height: 48px;
      border-radius: 99px;
      font-size: var(--fs-b3);
      cursor: pointer;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.1s;
      font-variant-numeric: tabular-nums;
      flex-shrink: 0;
      &:first-child {
        padding: 6px 16px 6px 6px;
        &:hover {
          background: #ffffff22;
          @media (prefers-color-scheme: dark) {
            background: #0000001a;
          }
        }
      }
      &:last-child {
        opacity: 0.5;
      }
      &:hover {
        transform: scale(0.99);
        opacity: 1;
      }
      &:active {
        transform: scale(0.97);
      }
    }
  }
`;

export default PlansPage;
