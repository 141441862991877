import React from 'react';
import { $t } from 'src/utils';
import { CardOption, EditablePerson, FilterInput, PXLayout, Toolbar } from '../components';
import { Button } from 'src/components';
import { useZipcode } from 'src/hooks/useZipcode';
import { useExplorer } from 'src/hooks/useExplorer';
import { useFormValue } from 'src/hooks/useFormValue';
import { useApplicants } from 'src/hooks/useApplicants';

const ZipcodePage = ({ onNext }) => {
  const { loading, possibleYears, data, upsertExplorer } = useExplorer();
  const [year, setYear] = useFormValue(data.coverageYear);
  const { isValidMembers, applicants, hasSpouse, addApplicant, updateApplicant, removeApplicant } =
    useApplicants(data.dependents);
  const {
    lookingUp,
    isValidLocation,
    zip,
    place,
    message,
    setZip,
    setPlace,
    showCounties,
    counties,
  } = useZipcode(data.zipcode);

  const disabled = !isValidLocation || !isValidMembers || !year;

  const next = async () => {
    await upsertExplorer({
      zip,
      place,
      year,
      applicants,
    });

    onNext(place);
  };

  const copy = {
    optionOE: $t({
      id: 'explorer/zip.coverage-year-oe',
      defaultMessage: 'Coverage starting next year, anyone can apply',
    }),
    optionSEP: $t({
      id: 'explorer/zip.coverage-year-sep',
      defaultMessage: 'Coverage requires a recent {link}',
      data: {
        link: (
          <a target="_blank" href="https://catch.co/guides/health/sep">
            {$t({
              id: 'QLE',
              defaultMessage: 'Qualifying Life Event',
            })}
          </a>
        ),
      },
    }),
  };

  return (
    <>
      <PXLayout
        terms
        long
        title={$t({
          id: 'explorer/zip.location-title',
          defaultMessage: 'Where do you live?',
        })}
        subtitle={$t({
          id: 'explorer/zip.location-subtitle',
          defaultMessage: 'Where you live can affect the ACA plans you are eligible to enroll in.',
        })}
        toolbar={
          <Toolbar half>
            <div></div>
            <Button primary onPress={next} disabled={disabled || loading || lookingUp}>
              {$t({ id: 'explorer/zip.btn', defaultMessage: 'Next' })}
            </Button>
          </Toolbar>
        }
      >
        <FilterInput
          autoFocus
          narrow
          required
          type="text"
          pattern="[0-9]{5}"
          keyboard="numeric"
          autocomplete="postal-code"
          title={$t({ id: 'labels.zip', defaultMessage: 'ZIP Code' })}
          name="zip"
          value={zip}
          label={$t({ id: 'labels.zip', defaultMessage: 'ZIP Code' })}
          placeholder={$t({ id: 'labels.zip', defaultMessage: 'ZIP Code' })}
          showExtra
          onChange={(v) => setZip(v?.slice(0, 5))}
          helpText={message}
        />
        {showCounties &&
          counties?.map((county) => (
            <CardOption
              prominent
              key={county?.name}
              title={county?.name}
              subtitle={county?.state}
              active={place?.name === county?.name}
              onPress={() => setPlace(county)}
            >
              {county?.name}
            </CardOption>
          ))}

        {possibleYears?.length > 1 && (
          <>
            <h3 style={{ marginBottom: 0, marginTop: 64 }}>
              {$t({
                id: 'explorer/zip.coverage-year-title',
                defaultMessage: 'When do you need coverage?',
              })}
            </h3>
            <p style={{ marginTop: 4, color: `var(--c-fg-1)` }}>
              {$t({
                id: 'explorer/zip.coverage-year-subtitle',
                defaultMessage: 'During Open Enrollment, you can enroll for next year.',
              })}
            </p>
            {possibleYears.map((yr, i) => (
              <CardOption
                key={yr}
                prominent
                title={yr}
                subtitle={i === 0 ? copy.optionOE : copy.optionSEP}
                active={year === yr}
                onPress={() => setYear(yr)}
              >
                {yr}
              </CardOption>
            ))}
          </>
        )}

        <h3 style={{ marginBottom: 0, marginTop: 64 }}>
          {$t({
            id: 'explorer/zip.household-title',
            defaultMessage: 'Who needs coverage?',
          })}
        </h3>
        <p style={{ marginTop: 4, color: `var(--c-fg-1)` }}>
          {$t({
            id: 'explorer/zip.household-subtitle',
            defaultMessage: 'Household members who need insurance',
          })}
        </p>
        <div>
          {applicants?.map((person, idx) => {
            return (
              <EditablePerson
                person={person}
                key={idx}
                isMe={idx === 0}
                onChange={(val) => updateApplicant(idx, val)}
                onRemove={() => removeApplicant(idx)}
              />
            );
          })}
        </div>
        <div
          style={{
            marginTop: 16,
            marginBottom: 24,
            display: 'grid',
            gridGap: 8,
            gridTemplateColumns: '1fr 1fr',
          }}
        >
          {!hasSpouse && (
            <Button
              small
              color="var(--c-border-light)"
              textColor="var(--c-fg-0)"
              onPress={() => addApplicant('SPOUSE')}
              ariaLabel={$t({ id: 'explorer/zip.btn.spouse', defaultMessage: 'Add Spouse' })}
            >
              + {$t({ id: 'explorer/zip.spouse', defaultMessage: 'Spouse' })}
            </Button>
          )}

          <Button
            small
            color="var(--c-border-light)"
            textColor="var(--c-fg-0)"
            onPress={() => addApplicant('CHILD')}
            ariaLabel={$t({ id: 'explorer/zip.btn.dependent', defaultMessage: 'Add Dependent' })}
          >
            + {$t({ id: 'explorer/zip.dependent', defaultMessage: 'Dependent' })}
          </Button>
        </div>
      </PXLayout>
    </>
  );
};

export default ZipcodePage;
