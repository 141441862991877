import React, { useState } from 'react';
import styled from 'styled-components';
import Button from './Button';

import { ReactComponent as Right } from '../iconic/arrow-right.svg';

const StyledField = styled.div`
  color: var(--c-fg-0);
  margin: 0 0px ${(p) => (p.raw ? 0 : 24)}px 0;
  max-width: 100%;
  width: 100%;
  --input-height: 64px;
  --input-padding: 8px;
  --input-radius: ${(p) => (p.raw ? 99 : 16)}px;
  max-width: ${(p) => (p.wide ? '100%' : '450px')};
`;

const StyledLabel = styled.label`
  font-size: ${(p) => (p.small ? 14.5 : 16)}px;
  font-weight: ${(p) => (p.light ? 400 : 500)};
  text-align: left;
  padding-right: 12px;
  margin: 0 0 8px;
  display: block;
  color: var(--c-fg-0);
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--input-height);
  border: 1px solid ${(p) => (p.focused || p.border ? 'var(--c-fg-0)' : 'transparent')};
  border-radius: var(--input-radius);
  box-shadow: ${(p) => p.focused && 'inset 0 0 1px var(--fg)'};
  background: ${(p) => (p.focused || p.raw ? 'var(--bgaa)' : 'var(--c-border-light)')};
  padding: 0 var(--input-padding);
  .prefix {
    font-size: var(--fs-b1);
    color: var(--c-fg-1);
    font-weight: 400;
  }
  .suffix {
    font-size: var(--fs-b1);
    color: var(--c-fg-1);
    font-weight: 400;
  }
  .prefix,
  .suffix {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 8px;
    display: flex;
    align-items: center;
  }
  button {
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: calc(var(--input-radius) - var(--input-padding));
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(var(--input-height) - var(--input-padding) * 2);
    opacity: ${(p) => (p.disabled ? 0.5 : 1)};

    ${(p) =>
      p.icon
        ? `
      width: calc(var(--input-height) - var(--input-padding) * 2);
      padding: 0;
    `
        : `
      span { padding-right: 8px; }
    `}
  }
`;

const StyledInput = styled.input`
  color: var(--c-fg-0);
  background: transparent;
  font-weight: 500;
  font-size: var(--fs-b1);
  font-family: var(--ff-headline);
  font-feature-settings: 'tnum';
  padding: 0 12px;
  width: 100%;
  max-width: ${(p) => (p.raw ? 150 : 800)}px;
  box-sizing: border-box;
  transition: 0.1s;
  border: none;

  ::placeholder {
    font-weight: 400;
  }
`;

const StyledSegment = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  align-items: center;
  font-size: ${12 - 1}px;
  font-weight: 500;
  padding: 0 4px 0 12px;
  height: ${(p) => (p.type === 'checkbox' ? '' : '100%')};
  overflow: hidden;
  > div {
    cursor: pointer;
    padding: 6px 8px;
    display: flex;
    align-items: center;
    border-radius: ${16 / 2}px;
    opacity: 0.5;
    height: 20px;
    justify-content: center;
    color: var(--fg);
    text-align: center;
    &:hover,
    &.active {
      color: ${(p) => p.activeColor};
      opacity: 1;
    }
    &.active {
      background-color: ${(p) => p.background};
      opacity: 1;
    }
  }
`;

// button {  onPress, disabled }

const FilterInput = ({
  label,
  type = 'text',
  options,
  value,
  onChange,
  small,
  placeholder,
  image,
  extraLabel,
  showExtra,
  optional,
  justLabel,
  keyboard,
  narrow,
  pattern,
  autocomplete,
  suffix,
  prefix,
  onKeyDown,
  name,
  title,
  required,
  raw,
  button,
  wide,
  autoFocus,
  helpText,
  border,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      {!!justLabel ? (
        <StyledLabel small={small}>
          {label} {!!optional && <span style={{ opacity: 0.5, fontWeight: 400 }}>optional</span>}
        </StyledLabel>
      ) : type === 'list' ? (
        <StyledField small={small} raw={raw}>
          {!!label && <StyledLabel small>{label} </StyledLabel>}
          <StyledSegment>
            {options?.map((item) => (
              <div
                key={item}
                className={value === item ? 'active' : ''}
                onClick={() => onChange(item)}
              >
                {item}
              </div>
            ))}
          </StyledSegment>
          {!!showExtra && (
            <StyledLabel light small style={{ opacity: 0.75, marginTop: 0, marginBottom: 8 }}>
              {extraLabel}
            </StyledLabel>
          )}
        </StyledField>
      ) : (
        <StyledField small={small} narrow={narrow} raw={raw}>
          {!!label && <StyledLabel for={name}>{label} </StyledLabel>}
          {!!showExtra && (
            <StyledLabel light small style={{ opacity: 0.75, marginTop: 0, marginBottom: 8 }}>
              {extraLabel}
            </StyledLabel>
          )}

          <StyledContainer
            border={border}
            icon={!button?.text}
            wide={wide}
            focused={isFocused}
            raw={raw}
            disabled={button?.disabled}
          >
            {!!prefix && <div className="prefix">{prefix}</div>}
            <StyledInput
              onBlur={() => setIsFocused(false)}
              onFocus={() => setIsFocused(true)}
              onKeyDown={onKeyDown}
              inputMode={keyboard}
              pattern={pattern}
              image={image}
              small={small}
              name={name}
              type={type}
              value={value || ''}
              placeholder={placeholder}
              autocomplete={autocomplete}
              checked={value}
              autoFocus={autoFocus}
              title={title}
              required={required}
              onChange={(event) => {
                onChange(event.target.value);
              }}
            />
            {!!suffix && <div className="suffix">{suffix}</div>}
            {!!button && (
              <Button small onPress={button?.onPress}>
                {button?.text && <span>{button?.text}</span>}
                <Right stroke="var(--c-bg-0)" />
              </Button>
            )}
          </StyledContainer>
          {helpText && (
            <StyledLabel light small style={{ opacity: 0.75, marginTop: 8, marginBottom: 8 }}>
              {helpText}
            </StyledLabel>
          )}
        </StyledField>
      )}
    </>
  );
};

export default FilterInput;
