export const DOMAIN = '@catch.co';
const HEY = `hey${DOMAIN}`;

export default {
  DEFAULT: HEY,
  HELP: `help${DOMAIN}`,
  HEY,
  PARTNERS: `partners${DOMAIN}`,
  PRESS: `press${DOMAIN}`,
};
