import React from 'react';
import { format } from 'date-fns';
import { $t } from './translate';

export const fCurrency = (value) => {
  if (typeof value === 'undefined' || value === null) {
    return value;
  }
  value = value.toString();

  value = value.replace(/[^0-9.]/g, ''); // Remove all chars except numbers and .
  const sections = value.split('.');

  // Remove any leading 0s apart from single 0
  if (sections[0] !== '0' && sections[0] !== '00') {
    sections[0] = sections[0].replace(/^0+/, '');
  } else {
    sections[0] = '0';
  }

  const addCommas = (num) => num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // If numbers exist after first .
  if (sections[1]) {
    // Join first two sections and truncate end section to length 2
    return '$' + addCommas(sections[0]) + '.' + sections[1].slice(0, 2);
    // If original value had a decimal place at the end, add it back
  } else if (value.indexOf('.') !== -1) {
    return '$' + addCommas(sections[0]) + '.';
    // Otherwise, return only section
  } else {
    return '$' + addCommas(sections[0]);
  }
};

export const groupBy = (array, property) => {
  if (!array) return [];
  var hash = {};
  for (var i = 0; i < array.length; i++) {
    if (!hash[array[i][property]]) hash[array[i][property]] = [];
    hash[array[i][property]].push(array[i]);
  }
  return hash;
};

export const formatPercentage = ({ number, numDecimals = 1 }) => {
  if (!number) return null;
  else if (number > 1 && number < 100) return number.toFixed(numDecimals);
  else return `${(number * 100).toFixed(numDecimals)}%`;
};

export const formatCurrency = ({ value }) => {
  const dollars = `${fCurrency(Math.floor(Math.abs(value || 0)))}`;
  const sign = value < 0 ? `–` : ``;
  return `${sign}${dollars}`;
};

const DATE_FORMATS = {
  TIME: 'hh:mmaaaaa',
  SHORT: 'MMM dd',
  MEDIUM: 'MMM dd, yyyy',
  LONG: 'eee, MMM dd, yyyy – hh:mmaaaaa',
  ISO: 'yyyy-MM-dd',
};

export const formatDate = ({ date, length = 'MEDIUM' }) => {
  if (!date) return null;
  else return format(new Date(date), DATE_FORMATS[length]);
};

export const getBankLogo = ({ logo, provider }) => {
  return logo ? `data:image/gif;base64,${logo}` : `/img/products/${provider?.toLowerCase()}.png`;
};

export const rowFormatters = {
  percentage: (value, options) => formatPercentage({ number: value, ...options }),
  currency: (value, options) => formatCurrency({ value, ...options }),
  enum: (str) =>
    !!str && str !== ''
      ? str
          .replace('_', ' ')
          .toProperCase()
          .replace('Ira', 'IRA')
          .replace('Llc', 'LLC')
          .replace('Work Type', '')
          .replace('Fee', '')
          .replace('_fee', '')
          .replace('Pto', 'Time Off')
      : '',
  date: (value, options) => formatDate({ date: value, ...options }),
  status: (value, options) => <div status={value}>{options?.valueLabel}</div>,
  mono: (value) => <div className="mono">{value}</div>,
};

export const initials = (person) => {
  if (!person) return '';
  const first = person?.nickname || person?.givenName;
  return `${first?.slice(0, 1) || ' '} ${person?.familyName?.slice(0, 1) || ' '}`;
};

export const makeCSV = (pmts) => {
  var rows = [
    [
      'payment_amount',
      'payment_date',
      'payment_status',
      'first',
      'last',
      'filing_status',
      'dob',
      'city',
      'state',
      'id',
    ],
  ];

  pmts?.map((pmt) => {
    rows.push([
      pmt?.amount,
      pmt?.createdOn,
      pmt?.status?.replace('TAX_PAYMENT_ADMIN_', ''),
      pmt?.person?.givenName,
      pmt?.person?.familyName,
      pmt?.person?.filingStatus,
      pmt?.person?.dob,
      pmt?.person?.legalAddress?.city,
      pmt?.person?.legalAddress?.state,
      pmt?.person?.id,
    ]);
  });

  let csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');

  var encodedUri = encodeURI(csvContent);
  window.open(encodedUri);
};

export const makeName = (user) =>
  !user?.nickname && !user?.givenName && !user?.familyName
    ? null
    : `${user?.nickname || user?.givenName} ${user?.familyName}`;

export const isActive = ({ array, item, key }) => {
  return array?.filter((el) => item?.[key] === el?.[key])?.length > 0;
};

export const formatMedicalName = (str) => {
  return str
    ?.toUpperCase()
    .replace('_', ' ')
    .replace(
      /\b(?!(MD|OD|DPT|PT|DDS|LCSWA|PA-C|DPM|PA|LCSW|DC|III|II|DMD|PHD|FNP|BC|C|OTL|CRNP|NP|LP|DO|OT|MSW|BCBA|CRNA|LPC|LSW|LCAS|LPC|XR|IR|MG)\b)\w+/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      },
    )
    ?.replace(' MG', 'mg');
};

export const formatList = (arr = []) => {
  if (arr.length === 1) {
    return arr[0];
  }

  if (arr.length === 2) {
    return $t({
      id: 'format.list-2',
      defaultMessage: `{first} and {second}`,
      data: { first: arr[0], second: arr[1] },
    });
  }

  if (arr.length === 3) {
    return $t({
      id: 'format.list-3',
      defaultMessage: `{first}, {second}, and {third}`,
      data: { first: arr[0], second: arr[1], third: arr[2] },
    });
  }

  return $t({
    id: 'format.list-more',
    defaultMessage: `{first}, {second}, and {count} others`,
    data: { first: arr[0], second: arr[1], count: arr.length - 2 },
  });
};
