import ENV from './env';

export const toProperCase = (str) =>
  str?.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

export {
  default as sources,
  TOP_SOURCES,
  RELATED_SOURCES,
  ALL_SOURCES,
  makeSources,
} from './sources';

export { closestSource } from './match';

export const isClient = () => typeof window === 'object' || typeof window !== 'undefined';

export const APPLE_URL =
  'https://apps.apple.com/us/app/catch-benefits/id1394681868?itsct=apps_box&itscg=30200';
export const GOOGLE_URL =
  'https://play.google.com/store/apps/details?id=com.catchcoapp&hl=en_US&gl=US';
export const WEB_URL = 'https://app.catch.co/auth/sign-up';
export const PX_URL = 'https://catch.co/explorer/';
export const WEB_SIGN_IN_URL = 'https://app.catch.co/auth/sign-in';

export const name = (person) => `${person?.firstName} ${person?.lastName}`;
export const postType = (post) =>
  post?.__typename === 'GraphCMS_Post'
    ? !!post?.displayBlog
      ? 'Blog'
      : 'Resource'
    : post?.__typename?.replace('GraphCMS_', '');

export const peopleString = (people) => {
  var str = '';
  const len = people?.length;
  if (len === 1) {
    str = name(people?.[0]);
  } else if (len === 2) {
    str = `${name(people?.[0])} and ${name(people?.[1])}`;
  } else {
    people?.forEach((person) => {
      str += name(person);
    });
  }
  return str;
};

export const interviewSubtitle = (interview) => `Interview with ${peopleString(interview?.people)}`;

export const oldHexToVar = {
  health: 'g-red',
  covid: 'g-lime',
  tax: 'g-orange',
  retirement: 'g-teal',
  freelancing: 'g-cerise',
  'portable-benefits': 'g-blue',
};
export const oldHexToRender = {
  health: 'health',
  covid: 'savings',
  tax: 'tax',
  retirement: 'retirement',
  freelancing: 'logo_render',
  'portable-benefits': 'logo_render',
};

export const any = (arr, key) => {
  var a = arr?.map((el) => el[key]) || [];
  return a.some((e) => e === true);
};

export const uniq = (arr) => {
  return Array.from(new Set(arr));
};
export const apiKey = ENV.marketplaceKey;

export { ENV };

export {
  oscarStates,
  ffmStates,
  offlineEnrollmentStates,
  getPremium,
  getDeductible,
  formatCostCoverage,
  formatSingleBenefit,
  formatCostCoverageTop,
  metalColors,
  metalLabels,
} from './health';
export {
  formatCurrency,
  isActive,
  formatMedicalName,
  formatPercentage,
  formatList,
} from './format';
export { canShare, share, copy, openLink } from './browser';
export { formatString, properCase, capitalize } from './string';
export { $t, locale, toggleLocale } from './translate';

export * from './partner';
export * from './calcEligibility';
