import React, { useContext, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SessionContext from 'src/context/SessionContext';

import { Banner, Footer, Navbar, Prefooter, RedirectModal } from '.';
import { Benefit } from 'src/types/session';
import { PageMetadata } from 'src/types/metadata';
import Metadata from 'src/components/Metadata';
import { DEFAULT_DESCRIPTION } from 'src/data/meta';
import { WebsiteToolType } from '../types/screens';

function dateCheck(from, to) {
  var fDate, lDate, cDate;
  fDate = Date.parse(from);
  lDate = Date.parse(to);
  cDate = Date.parse(Date());
  if (cDate <= lDate && cDate >= fDate) {
    return true;
  }
  return false;
}

String.prototype.toProperCase = function () {
  return `${this}`?.toLowerCase() === 'identity'
    ? this.charAt(0).toUpperCase() + this.substr(1).toLowerCase()
    : this.toUpperCase()
        .replaceAll('_', ' ')
        .replaceAll(
          /(?!KYC|CIP|IRA|DDA|DMI|SVI|OEP|SEP|MEC|APTC|CSR|CSRS|EDE|ID|MSCI|ETF|SIPC|FDIC|NONESCMEC|ACC|RF1|RF2|RF3|RF4\b)\b\w+\S*/g,
          function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          },
        );
};

export interface PageProps {
  /** @deprecated use metadata.title */
  title: string;
  /** @deprecated use metadata.description */
  description?: string;
  /** @deprecated use metadata.keywords */
  kw?: any;
  /** @deprecated use metadata.image */
  image?: any;
  /** @deprecated use metadata.video */
  video?: any;
  /** @deprecated use metadata.canonical */
  canonical?: any;
  noIndex?: boolean;
  /** @deprecated use metadata.date */
  date?: any;
  section?: any;
  tag?: any;
  data?: any;
  twitter?: any;
  type?: any;
  dark?: any;
  prefooter?: any;
  footer?: any;
  /** @deprecated use metadata.canonical */
  path?: any; // for canonical
  signupUrl?: any;
  color?: any;
  darkColor?: any;
  navBorder?: boolean;
  hideLinks?: boolean;
  hideBanner?: boolean;
  unfixNav?: boolean;
  transparentNav?: boolean;
  children?: any;
  vertical?: Benefit;
  source?: string;
  metadata: PageMetadata;
  context?: WebsiteToolType;
}

/**
 * @todo income source cascading and associations
 */
const Page = ({
  children,
  title = 'Benefits for the Self-Employed',
  description = DEFAULT_DESCRIPTION,
  kw = [],
  image = '/graphics/OG_Catch.jpg',
  video,
  canonical,
  noIndex,
  date,
  type = 'website',
  dark,
  prefooter,
  footer,
  signupUrl,
  color = '#FFFFFF',
  darkColor = '#000000',
  navBorder,
  hideLinks,
  hideBanner,
  unfixNav,
  transparentNav,
  hideButtons,
  metadata,
  skipLd,
  showFooter,
  context,
  progress,
  showPSBanner,
  navBg,
}: PageProps) => {
  const banners = useStaticQuery(graphql`
    query BannerQuery {
      allGraphCmsBanner {
        nodes {
          id
          link
          startDate
          title
          text
          endDate
          color
        }
      }
    }
  `);

  const isApp = /Slasher|Explorer|Calculator/.test(context);

  const banner = banners?.allGraphCmsBanner?.nodes?.filter((banner) =>
    dateCheck(banner.startDate, banner.endDate),
  )?.[0];

  const { signupUrl: url, signinUrl, identify } = useContext(SessionContext);

  useEffect(() => {
    identify();
  }, [identify]);

  return (
    <div className={process.env.NODE_ENV === 'development' ? 'defv' : ''}>
      <div className={dark ? 'dark' : 'light'}>
        <Navbar
          bg={navBg}
          isApp={isApp}
          unfix={unfixNav}
          border={isApp ? false : navBorder}
          color={banner ? darkColor : color}
          darkColor={banner ? color : darkColor}
          signupUrl={signupUrl || url}
          signinUrl={signinUrl || url}
          hideLinks={hideLinks || isApp}
          showBenefitsAdvisor={isApp}
          hideButtons={hideButtons || isApp}
          dark={dark}
          transparent={transparentNav || isApp}
          progress={progress}
          showPSBanner={showPSBanner} // @todo this should be a banner
          banner={
            !!banner && (
              <Banner
                params={url?.slice(url?.indexOf('?'))}
                text={banner.text}
                color={banner.color}
                link={banner.link || signupUrl}
                accentColor={color}
                hideBanner={hideBanner}
              />
            )
          }
        />
      </div>

      <Metadata
        skipLd={skipLd}
        metadata={{
          title,
          description,
          image,
          video,
          canonical: canonical,
          keywords: kw,
          datePublished: date,
          pageType: type === 'article' ? 'BlogPost' : 'Other',
          color: banner ? darkColor : color,
          darkColor: banner ? color : darkColor,
          noIndex,
          hideAppSuggestion: isApp,
          ...metadata,
        }}
      />

      <div>{children}</div>

      {!isApp && <>{prefooter || <Prefooter signupUrl={signupUrl || url} />}</>}
      {(!isApp || !!showFooter) && <>{footer || <Footer hideLinks={isApp} />}</>}

      <RedirectModal />
    </div>
  );
};

export default Page;
