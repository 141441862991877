import React, { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import client from 'src/gatsby-plugin-apollo/client';

import { SessionContext } from 'src/context/SessionContext';
import { useAnnotation, usePartner, usePlanCoverage } from 'src/hooks';
import { HealthPolicy } from 'src/components';
import { PLAN_FULL } from 'src/data';
import { navigate } from 'gatsby';
import { goTo } from 'src/utils/links';
import { usePublicSession } from 'src/hooks/usePublicSession';

const SinglePlanView = ({ id, hide, onInfo, isHandoff }) => {
  const { sessionID } = usePublicSession();
  const { partner, signupUrl, healthContext } = useContext(SessionContext);

  const { color } = usePartner({ slug: partner });

  const { data } = useQuery(PLAN_FULL, {
    skip: !sessionID || !id,
    variables: {
      id: sessionID,
      planID: id,
    },
  });

  const scored = client.readFragment({
    id: `ScoredHealthPlan:${id}`,
    fragment: gql`
      fragment cachedScoredPlan on ScoredHealthPlan {
        id
        providerCoverage {
          npi
          name
          isCovered
        }
        drugCoverage {
          rxcui
          name
          isCovered
          isGenericCovered
        }
      }
    `,
  });

  const p = data?.publicHealthPlanDetails;
  const { annotations } = useAnnotation(scored);
  const { coverage, topLevelItems } = usePlanCoverage(p);

  const isDental =
    p?.metalLevel?.toLowerCase() === 'high' || p?.metalLevel?.toLowerCase() === 'low';

  return (
    !hide && (
      <HealthPolicy
        id={sessionID}
        isDental={isDental}
        name={p?.name}
        metal={p?.metalLevel}
        type={p?.type}
        grossPremium={p?.premium}
        netPremium={p?.premiumWithCredit}
        brochure={p?.brochureURL}
        issuer={p?.issuer?.name}
        moop={p?.deductibles?.[0]?.amount}
        benefits={coverage}
        deductibles={p?.deductibles}
        qualityRating={p?.qualityRating}
        annotations={annotations}
        topLevelItems={topLevelItems}
        providerPlan={p}
        color={color}
        healthContext={healthContext}
        onNext={() =>
          isHandoff
            ? navigate(`/explorer/handoff/?planId=${id}&sid=${sid}`)
            : goTo(signupUrl, { showCountdown: true })
        }
        oopc={p?.oopc}
        onInfo={onInfo}
        standard={p?.isStandard}
      />
    )
  );
};

export default SinglePlanView;
