import React from 'react';
import { Button } from 'src/components';

import { FilterInput, PXLayout, Toolbar, PXSection as Section } from 'src/components';
import { $t } from 'src/utils';
import { useExplorer } from 'src/hooks/useExplorer';
import { useFormValue } from 'src/hooks/useFormValue';

const IntroPage = ({ onNext }) => {
  const { data, upsertExplorer } = useExplorer();
  const [income, setIncome] = useFormValue(data?.income);

  const handleNext = async () => {
    upsertExplorer({ income: income || null });
    onNext();
  };

  return (
    <>
      <PXLayout
        pretitle={$t({ id: 'optional', defaultMessage: 'Optional' })}
        title={$t({
          id: 'explorer/income.title',
          defaultMessage: 'What is your household income?',
        })}
        subtitle={$t({
          id: 'explorer/income.subtitle',
          defaultMessage:
            'You may qualify for savings on your health insurance if your income is below a certain threshold',
        })}
        sidebarContent={
          <Section
            title={$t({
              id: 'explorer/income.sidebar-title',
              defaultMessage: 'Estimate your household income',
            })}
            subtitle={$t({
              id: 'explorer/income.sidebar-subtitle',
              defaultMessage:
                'Enter in the amount you expect to earn this year. If you file jointly, include your spouse or others who may be included in your household.',
            })}
          />
        }
        toolbar={
          <Toolbar half>
            <div></div>
            <Button type={!!income ? 'primary' : undefined} onPress={handleNext}>
              {!income
                ? $t({ id: 'skip', defaultMessage: 'Skip' })
                : $t({ id: 'explorer/income.btn', defaultMessage: 'See plans' })}
            </Button>
          </Toolbar>
        }
      >
        <form onSubmit={handleNext} action="javascript:void(0);">
          <FilterInput
            optional
            narrow
            prefix="$"
            suffix={$t({ id: 'explorer/income.input-suffix', defaultMessage: 'per year' })}
            image="income"
            type="text"
            keyboard="numeric"
            value={income}
            title={$t({ id: 'explorer/income.input-title', defaultMessage: 'Annual Income' })}
            helpText={$t({
              id: 'explorer/income.input-magi',
              defaultMessage:
                'Enter the Modified Adjusted Gross Income (MAGI) for your entire tax household',
            })}
            name="income"
            pattern="[0-9]{1,8}"
            placeholder="0"
            showExtra
            onChange={(v) => {
              setIncome(v ? parseInt(v) : '');
            }}
          />

          <input type="submit" />
        </form>
      </PXLayout>
    </>
  );
};

export default IntroPage;
