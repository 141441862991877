import { useCallback, useEffect } from 'react';
import useLocalStorage from 'src/utils/useLocalStorage';
import paths from 'src/constants/paths';
import { useLocation } from '@reach/router';

//aetna_session expires after 1 hour of activity
const SESSION_TIMEOUT = 60 * 60 * 1000; //1 hr in ms
const UPDATE_ACTIVITY_INTERVAL = 60 * 1000; //1 min in ms

//tracks last activity in /aetna flow
export const useAetnaSession = (): { isAetnaSessionActive: boolean } => {
  const [aetnaSessionLastActivity, setAetnaSessionLastActivity] = useLocalStorage<number | null>(
    'aetna_session',
    null,
  );

  const isAetnaSessionActive = aetnaSessionLastActivity
    ? Date.now() - aetnaSessionLastActivity < SESSION_TIMEOUT
    : false;

  const { pathname } = useLocation();

  const startSession = () => {
    setAetnaSessionLastActivity(Date.now());
  };

  const extendSession = useCallback(() => {
    setAetnaSessionLastActivity((lastActivity) => {
      const isActive = Date.now() - lastActivity < SESSION_TIMEOUT;
      return isActive ? Date.now() : lastActivity;
    });
  }, [setAetnaSessionLastActivity]);

  useEffect(() => {
    const addListeners = () => {
      document.addEventListener('mousemove', handleActivity);
      document.addEventListener('keydown', handleActivity);
    };

    const handleActivity = () => {
      extendSession();
      cleanup();
      setTimeout(addListeners, UPDATE_ACTIVITY_INTERVAL);
    };

    const cleanup = () => {
      document.removeEventListener('mousemove', handleActivity);
      document.removeEventListener('keydown', handleActivity);
    };

    addListeners();

    return cleanup;
  }, []);

  useEffect(() => {
    //start session when we land on an Aetna page:
    if (pathname.includes(paths.AETNA)) {
      startSession();
    }
  }, [pathname]);

  return {
    isAetnaSessionActive,
  };
};
