import React from 'react';
import styled from 'styled-components';

import { Button } from 'src/components';

const SBox = styled.div`
  padding: ${(p) => (p.small ? '16px 24px' : '24px')};
  background: ${(p) => p.bgLight || p.bg || 'var(--c-bg-gray)'};
  @media (prefers-color-scheme: dark) {
    background: ${(p) => p.bg || 'var(--c-bg-gray)'};
  }
  border-radius: 16px;
  margin: 4px 0;
  display: flex;
  color: var(--c-fg-0);
  @media (max-width: 768px) {
    flex-direction: column;
    button {
      margin-left: 30px;
      margin-top: 16px;
    }
  }
  img {
    filter: var(--svgFilter);
    max-width: 100%;
    width: 24px;
    height: 24px;
    align-self: flex-start;
    flex-grow: 0;
  }
  p,
  b2 {
    margin: 0 !important;
    padding: 0 !important;
  }
  b2 {
    font-weight: 500;
    flex-grow: 1;
    > b2 {
      font-weight: 400;
    }
  }

  button {
    align-self: flex-start;
    flex-shrink: 0;
    margin-left: 24px;
  }
`;

export const Callout = ({
  imageSrc = '/symbol-dark.png',
  eb,
  title,
  text,
  buttonText,
  buttonUrl,
  bg,
  bgLight,
  smallText,
}) => {
  return (
    <SBox bg={bg} small={smallText} bgLight={bgLight}>
      <div className="flex">
        <img
          loading="lazy"
          src={imageSrc}
          height={24}
          width={24}
          style={{
            flex: 0,
            flexGrow: 0,
            margin: 0,
            marginRight: 12,
            marginLeft: -6,
          }}
        />
        <b2>
          {!!eb && (
            <eb className="o50" style={{ marginTop: -2 }}>
              {eb || 'tl;dr'}
            </eb>
          )}
          {title}
          {!!text &&
            (smallText ? (
              <b3 style={{ marginTop: 2, color: 'var(--c-fg-1)' }}>{text}</b3>
            ) : (
              <b2 style={{ marginTop: 2, color: 'var(--c-fg-1)' }}>{text}</b2>
            ))}
        </b2>
      </div>
      {!!buttonText && (
        <Button smallMobile onPress={() => window.open(buttonUrl)}>
          {buttonText}
        </Button>
      )}
    </SBox>
  );
};
export default Callout;
