import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

const COLORS = {
  purple: 'var(--c-purple)',
  grey: 'var(--c-fg-2)',
};

const SIZES = {
  xxxxxlarge: 70,
  xxxxlarge: 64,
  xxxlarge: 52,
  xxlarge: 32,
  xlarge: 24,
  large: 20,
  medium: 18,
  small: 16,
  xsmall: 14,
};

const SSpan = styled.span`
  color: ${({ $color }) => COLORS[$color]};
  font-size: ${({ $size }) => !!$size && SIZES[$size]}px;
  font-weight: ${({ $weight }) => !!$weight && $weight};
  line-height: 1;
  margin: 0;
  margin-bottom: ${({ $flush }) => $flush && '0'};
  text-align: ${({ $align }) => $align === 'left' && 'left'};
  text-transform: ${({ $casing }) => $casing === 'upper' && 'uppercase'};

  ${({ $align }) =>
    $align === 'center' &&
    css`
      display: block;
      text-align: center;
    `}

  ${({ $element }) =>
    $element === 'figure' &&
    css`
      margin: 0;
    `}

  ${({ $element }) =>
    $element === 'p' &&
    css`
      line-height: 1.6;
      text-wrap: pretty;
    `}

  ${({ $element, $flush }) =>
    $element === 'p' &&
    !$flush &&
    css`
      margin-bottom: 1lh;
    `}
`;

export interface TextProps {
  align?: 'center' | 'left';
  casing?: 'upper';
  children: ReactNode;
  color?: 'purple' | 'grey';
  element?:
    | 'div'
    | 'figcaption'
    | 'figure'
    | 'footer'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'p'
    | 'section'
    | 'span';
  flush?: boolean;
  size?:
    | 'xxxxxlarge'
    | 'xxxxlarge'
    | 'xxxlarge'
    | 'xxlarge'
    | 'xlarge'
    | 'large'
    | 'medium'
    | 'small'
    | 'xsmall';
  weight?: 'bold';
}

export default function Text({
  align,
  casing,
  color,
  element = 'span',
  flush,
  size,
  weight,
  ...props
}: TextProps) {
  return (
    <SSpan
      as={element}
      $align={align}
      $casing={casing}
      $color={color}
      $element={element}
      $flush={flush}
      $size={size}
      $weight={weight}
      {...props}
    />
  );
}
