import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 12px;
  font-weight: 400;
  color: var(--c-fg-1);
  font-size: 13px;

  strong {
    font-weight: 500;
  }

  a {
    text-decoration-thickness: 1px !important;
    text-decoration-color: var(--c-fg-3) !important;
    text-underline-offset: 2.5px !important;
    text-decoration: underline;

    &:hover {
      color: var(--c-fg-0);
    }
  }
`;

export const Disclosures = () => (
  <Container>
    By continuing, you agree to the <Link to="/legal/terms">Terms of Use</Link> and acknowledge our{' '}
    <Link to="/legal/privacy">Privacy Policy</Link>. All licensed insurance broker services are
    provided exclusively by Catch Insurance, LLC (NPN 18986211).
  </Container>
);

export default Disclosures;
