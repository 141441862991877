import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Icon, IconType } from 'src/kit';

const STestNavLink = styled.div`
  position: relative;
  z-index: 999999999999999999999999;
  margin-top: -1px;
  .navlink {
    padding: 8px 12px 8px;
    margin: 0 1px;
    font-weight: 450;
    font-size: 15px;
    font-variation-settings: 'opsz' 20;
    color: var(--c-fg-0);
    opacity: 0.75;
    font-family: var(--ff-medium);
    vertical-align: baseline;
    border-radius: 12px;
    transition: 0.1s;
    &.active {
      color: var(--c-fg-0);
      font-weight: 500;
      // letter-spacing: -0.15px;
      opacity: 1;
      background: var(--c-border-light);
    }
  }
  .mmenu {
    position: absolute;
    pointer-events: none;
    top: 16px;
    height: 240px;
    width: 270px;
    z-index: 999999999999999999999999;
    margin-left: -150%;
    padding-left: 200%;
    box-sizing: content-box;
    transform: scale(0.95);
    opacity: 0;
    transition: 0.15s;
    transform-origin: top;
    > div {
      background: var(--bgc);
      backdrop-filter: blur(25px);
      border: 1px solid var(--c-border);
      box-shadow: 0 6px 12px -3px #00000022;
      width: 100%;
      margin-top: 24px;
      margin-left: -135px;
      z-index: 999999999999999999999999;

      border-radius: 18px;
      overflow: hidden;
      padding: 8px;
    }
  }

  &:hover,
  &:active {
    .navlink {
      background: var(--c-border-light);
      color: var(--c-fg-0);
    }
    .mmenu {
      display: block;
      pointer-events: auto;
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

export const NavLink = ({
  text,
  path = '/',
  active,
  hideSmall,
  hideLinks,
  skipMatch,
  children,
  onClick,
}) => (
  <STestNavLink onClick={onClick}>
    {/https/.test(path) ? (
      <a
        href={path}
        className={`${active && 'active'} navlink ${!!hideSmall && 'hide-sm'}   ${
          hideLinks && 'hide-partner'
        } `}
      >
        {text}
      </a>
    ) : (
      <Link
        to={path}
        partiallyActive={true}
        activeClassName={skipMatch ? '' : 'active'}
        className={`navlink ${!!hideSmall && 'hide-sm'}   ${hideLinks && 'hide-partner'} `}
      >
        {text}
      </Link>
    )}
    {children && (
      <div className="mmenu">
        <div>{children}</div>
      </div>
    )}
  </STestNavLink>
);

const SMNavLink = styled.div`
  border-bottom: 1px solid var(--c-border-light);
  .mnavlink {
    padding: 16px 0;
    margin: 0;
    width: 100%;
    cursor: pointer;
    font-size: var(--fs-b2) !important;
    line-height: 1;
    width: 100%;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background: ${(p) => (p.open ? 'var(--c-bg-gray)' : 'transparent')};
    padding: 0 var(--side-margin);
    .trailicon {
      svg {
        transform: rotate(${(p) => (p.open ? '90deg' : '0deg')});
        transition: transform 0.25s cubic-bezier(0.42, 0, 0.02, 1.18);
      }
    }
    &:active {
      background: var(--c-border-light);
    }
  }
  .mnavlinkbody {
    background: ${(p) => (p.open ? 'var(--c-bg-gray)' : 'transparent')};
    height: ${(p) => (p.open ? p.height + 12 : 0)}px;
    overflow: hidden;
    width: 100%;
    display: block;
    will-change: height;
    transition: height 0.25s cubic-bezier(0.42, 0, 0.02, 1.18);
    div {
      border: none !important;
    }
    .mnavlink {
      font-weight: 400;
      color: var(--c-fg-1);
      border: none;
      height: 48px;
    }
  }
`;

export const MNavLink = ({ text, path = '/', icon, children, onClick }: { icon?: IconType }) => {
  const [open, setOpen] = useState();
  const body = useRef(null);

  return (
    <SMNavLink onClick={onClick} open={open} height={body.current?.clientHeight}>
      {children ? (
        <div>
          <div className="mnavlink" onClick={() => setOpen(!open)}>
            {text}
            <div className="trailicon">{!!children && <Icon type="ChevronRight" />}</div>
          </div>
          <div className="mnavlinkbody">
            <div ref={body}>{children}</div>
          </div>
        </div>
      ) : (
        <Link to={path} partiallyActive={true} className="mnavlink no-children">
          {text}
          {icon && <Icon type={icon} />}
        </Link>
      )}
    </SMNavLink>
  );
};

export default NavLink;
