import React from 'react';
import styled from 'styled-components';
import { Container } from '..';

const SHeader = styled.div`
  background: ${(p) => p.bg};
  padding-top: calc(var(--s-nav-height) + var(--padding-section-sm));
  padding-bottom: calc(var(--padding-section-sm));
  padding-left: var(--side-margin);
  padding-right: var(--side-margin);
  color: var(--c-fg-0);
  h3 {
    color: var(--c-fg-1);
    max-width: var(--width-read);
  }
`;

export const Header = ({ eb, title, text, narrow, dark, bg = 'var(--c-bg-gray)', children }) => {
  return (
    <div className={dark && 'dark'}>
      <SHeader bg={bg} hasSubtitle={!!text}>
        <Container narrow={narrow}>
          {!!eb && <eb>{eb}</eb>}
          <h2 className="r">{title}</h2>
          {!!text && <h3 className="l">{text}</h3>}
          {children}
        </Container>
      </SHeader>
    </div>
  );
};

export default Header;
