import React from 'react';
import styled from 'styled-components';
import { Icon, IconType } from 'src/kit';

const SDiv = styled.span`
  display: ${(p: TextWithIconProps) => (p.block ? 'flex' : 'inline-flex')};
  align-items: center;
`;

const SIcon = styled(Icon)`
  margin-right: 6px;
`;

interface TextWithIconProps {
  block?: boolean;
  children: string;
  icon?: IconType;
}

export default function TextWithIcon({ children, icon, ...props }: TextWithIconProps) {
  return (
    <SDiv {...props}>
      {!!icon && <SIcon type={icon} />}
      {children}
    </SDiv>
  );
}
