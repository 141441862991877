import React from 'react';

import { ReactComponent as Checkmark } from 'src/iconic/checkmark.svg';
import { ReactComponent as ChevronRight } from 'src/iconic/chevron-right.svg';
import { ReactComponent as ChevronDown } from 'src/iconic/chevron-down.svg';
import { ReactComponent as Close } from 'src/iconic/close.svg';
import { ReactComponent as Globe } from 'src/iconic/globe.svg';
import { ReactComponent as Help } from 'src/iconic/annotation.svg';
import { ReactComponent as Menu } from 'src/iconic/menu.svg';
import { ReactComponent as Phone } from 'src/iconic/phone.svg';
import { ReactComponent as Mail } from 'src/iconic/mail.svg';

/**
 * Note: when adding icons here, please ensure that:
 * 1) any hex values are replaced with currentColor
 * 2) the width/height svg properties are removed
 *
 * This will allow the styling to be applied properly
 */
const icons = {
  Checkmark,
  ChevronRight,
  ChevronDown,
  Close,
  Globe,
  Help,
  Menu,
  Phone,
  Mail,
};

export type IconType = keyof typeof icons;

export interface IconProps {
  type: IconType;
  size?: number;
  color?: string;
  className?: string;
}

export function Icon({ size = 24, type, color = 'var(--c-fg-0)', className }: IconProps) {
  const IconComponent = icons[type];

  if (IconComponent) {
    return <IconComponent width={size} height={size} color={color} className={className} />;
  }

  return null;
}
